import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/home/index"),
    }, {
      path: "/about",
      name: "about",
      component: () => import("@/views/home/about"),
    }, {
      path: "/service",
      name: "service",
      component: () => import("@/views/home/service"),
    }, {
      path: "/cooperation",
      name: "cooperation",
      component: () => import("@/views/home/cooperation"),
    }, {
      path: "/news",
      name: "news",
      component: () => import("@/views/home/news"),
    }, {
      path: "/join",
      name: "join",
      component: () => import("@/views/home/join"),
    }, {
      path: "/newsDetail",
      name: "newsDetail",
      component: () => import("@/views/home/newsDetail"),
    }, {
      path: "/positionDetail",
      name: "positionDetail",
      component: () => import("@/views/home/positionDetail"),
    }, {
      path: "/positionAll",
      name: "positionAll",
      component: () => import("@/views/home/positionAll"),
    }, {
      path: "/seekJob",
      name: "seekJob",
      component: () => import("@/views/home/seekJob"),
    }, {
      path: "/honors",
      name: "honors",
      component: () => import("@/views/home/honors"),
    }, {
      path: "/hotmap",
      name: "hotmap",
      component: () => import("@/views/home/hotmap"),
    }
  ],
});
export default router;
